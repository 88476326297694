<template>
    <section class="content">
        <div class="table-responsivex">
            <table class="table table-hover" ref="tableuser">
                <thead>
                    <tr>
                        <th>Hari</th>
                        <th>Tanggal</th>
                        <th>Tujuan</th>
                        <th>Agenda</th>
                        <th>Berita Acara</th>
                        <th>Dokumentasi(upload foto)</th>
                        <th>Keterangan(Catatan khusus)</th>
                        <th>Follow Up</th>
                    </tr>
                </thead>
                <tbody @click="handleClick">
                </tbody>
            </table>
        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tanggal</label>
                                <input type="hidden" name="row_id" value="" v-model="form.id"/>
                                <input type="hidden" name="bukti" value="" v-model="form.bukti" id="bukti" />
                                <datepicker v-model="form.tanggal" :options="{format:'dd/mm/yyyy', startView: 'days', minViewMode: 'days'}" :change="onChange()"/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Follow Up</label>
                                <input id="followup" class="form-control followup" v-model="form.followup" type="text" required="required" name="followup" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Hari</label>
                                <input id="hari" class="form-control hari" v-model="form.hari" type="text" readonly name="hari" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Keterangan</label>
                                <textarea class="form-control" v-model="form.keterangan"></textarea>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tujuan</label>
                                <input id="tujuan" class="form-control tujuan" v-model="form.tujuan" type="text" required="required" name="tujuan" />
                            </div>
                            <div class="col-md-6 form-group">
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Agenda</label>
                                <input id="agenda" class="form-control agenda" v-model="form.agenda" type="text" required="required" name="agenda" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="inputFile">Bukti</label>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFoto(this)">
                                    <label class="custom-file-label" for="inputFile" ref="imgname">Choose file</label>
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Berita Acara</label>
                                <input id="beritaacara" class="form-control beritaacara" v-model="form.beritaacara" type="text" required="required" name="beritaacara" />
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import Swal from "sweetalert2";
import $ from 'jquery';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import moment from 'moment';
import datepicker from '@/components/Datepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

export default {
    name: 'LaporanDinasKeluar',
    components: {
        vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            optMarketing: [],
            formTitle: 'Tambah',
            form: {
                start: moment().format('DD/MM/YYYY'),
                end: moment().format('DD/MM/YYYY'),
                tanggal: '',
                followup: '',
                hari: '',
                keterangan:'',
                tujuan:'',
                agenda: '',
                bukti:'',
                beritaacara:''
            },
            form_data: ''
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
        onChange: function() {
            var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
            namahari = namahari.split(" ");
            if (this.form.tanggal) {
                var split = this.form.tanggal.split('/')
                var tgl = new Date(split[2],split[1]-1,split[0]);
                var hari = tgl.getDay();

                this.form.hari = namahari[hari];
            }
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var self = this;

            var file_data = $('#inputFile').prop('files');
            if (file_data.length == 0) {
                ev.preventDefault();
                self.form.bukti = this.form.bukti;
                var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                var urlSubmit = '/pr/laporan_dinas_luar';
                if (this.method == 'PUT') urlSubmit = '/pr/laporan_dinas_luar/' + this.form.id;

                authFetch(urlSubmit, {
                        method: this.method,
                        body: data
                    })
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {

                        this.errors = [];
                        if (!js.success) {
                            console.log(js.details)

                            for (var key in js.details) {
                                if (js.details.hasOwnProperty(key)) {
                                    this.errors.push(js.details[key])
                                }
                            }

                            return;
                        }
                        Swal.fire({
                            icon: 'success',
                            title: 'Informasi',
                            text: 'Data telah tersimpan.'
                        })
                        this.table.api().ajax.reload();
                        $(e.formDialog).modal('hide');
                        self.form = {};
                    });
                return false;
            } else {
                this.doUpload().then((res) => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}

                    return res.json();
                    
                }).then(js => {
                    console.log('result')
                    console.log(js)
                    this.errors = [];
                    if (!js.status) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    } else {
                        self.form.bukti = js.message[0].id;

                        var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                        var urlSubmit = '/pr/laporan_dinas_luar';
                        if (this.method == 'PUT') urlSubmit = '/pr/laporan_dinas_luar/' + this.form.id;

                        authFetch(urlSubmit, {
                                method: this.method,
                                body: data
                            })
                            .then(res => {
                                if (res.status === 201) {

                                } else if (res.status === 400) {}
                                return res.json();
                            })
                            .then(js => {

                                this.errors = [];
                                if (!js.success) {
                                    console.log(js.details)

                                    for (var key in js.details) {
                                        if (js.details.hasOwnProperty(key)) {
                                            this.errors.push(js.details[key])
                                        }
                                    }

                                    return;
                                }
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Informasi',
                                    text: 'Data telah tersimpan.'
                                })
                                this.table.api().ajax.reload();
                                $(e.formDialog).modal('hide');
                                self.form = {};
                            });
                    }
                });
                // return false;
            }

            ev.preventDefault();
        },
        uploadFoto : function (file) {
            const e = this.$refs;
            var file_data = $('#inputFile').prop('files');
            var form_data = new FormData();
            var fileInput = $('#inputFile')[0];
            $.each(fileInput.files, function(k,file){
              form_data.append('file', file);
              $(e.imgname).html(file.name);
            });
            form_data.append('subdir', '/laporan-dinas-luar/');
            form_data.append('id', this.form.id);
            this.form_data = form_data
            return false
        },
        doUpload: function() {
            return authFetch('/pr/laporan_dinas_luar/upload_data', {
                method: 'POST',
                body: this.form_data,
                headers: {
                  'Content-Type': null,
                }
            })
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            title: "",
            roles: this.$route.params.roles,
            ajax: "/pr/laporan_dinas_luar",
            scrollX: true,
            columns: [
                { data: "tanggal_mulai" },
                { data: "start" },
                { data: "tujuan_kedinasan" },
                { data: "title" },
                { data: "note_hasil" },
                { data: "followup" },
                { data: "description" },
                { data: "followup" },
            ],
            filterBy: [1, 2, 3, 4],
            rowCallback: function(row, data) {
                var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
                namahari = namahari.split(" ");
                var tgl = new Date(data.tanggal_mulai);
                var hari = tgl.getDay();
                $('td:eq(0)', row).html(namahari[hari]);

                if (data.bukti != '0') {
                    if (data.file_path) {
                        $('td:eq(5)', row).html(
                '<img width="100px;" class="bd-placeholder-img card-img-top" src="' +
                window.$apiUrl +
                "/uploads/" +
                data.file_path +
                "/small/" +
                data.file_name +
                '" v-if="todo.file_path">');
                    }
                } else {
                    $('td:eq(5)', row).html('')
                }
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Laporan Dinas';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    
                    var namahari = ("Minggu Senin Selasa Rabu Kamis Jumat Sabtu");
                    namahari = namahari.split(" ");
                    var tgl = new Date(evt.data.tanggal_mulai);
                    var hari = tgl.getDay();

                    self.form.tanggal = evt.data.start;
                    self.form.followup = evt.data.followup;
                    self.form.hari = namahari[hari];
                    self.form.keterangan = evt.data.description;
                    self.form.tujuan = evt.data.tujuan_kedinasan;
                    self.form.agenda = evt.data.title;
                    self.form.bukti = evt.data.bukti;
                    self.form.beritaacara = evt.data.note_hasil;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Laporan Dinas';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                        console.log(evt);
                        Swal.fire({
                            title: "Hapus data?",
                            icon: "question",
                            denyButtonText: '<i class="fa fa-times"></i> Hapus',
                            showCancelButton: true,
                            showDenyButton: true,
                            showConfirmButton: false,
                        }).then((result) => {
                            if (result.isDenied) {
                            authFetch("/pr/laporan_dinas_luar/" + evt.data.id, {
                                method: "DELETE",
                                body: "id=" + evt.data.id,
                            })
                                .then((res) => {
                                return res.json();
                                })
                                .then((js) => {
                                this.table.api().ajax.reload();
                                });
                            }
                        });
                }

            },
        });

    }
}
</script>